import React from "react";
import Layout from "../layout";
import Slider from "../slider/slider";
// import Upcomingwebinar from "../upcomingwebinar";
// import OurExperts from '../OurExperts'
// import Testimonials from '../Testimonials'

function Home() {
  return (
    <Layout>
      <Slider />
      {/* <Upcomingwebinar /> */}
    </Layout>
  );
}

export default Home;
